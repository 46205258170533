import React from 'react'
import { StaticQuery, graphql,Link } from 'gatsby'
import styled, { keyframes } from 'styled-components'
import Typewriter from 'typewriter-effect'
import LoopVideo from './assets/vid_fon.mp4'
import Glitch from 'components/glitch'
import Swal from 'sweetalert2'
import ticket from 'sections/images/gw-01.png'
import msj from 'sections/images/gw-08.png'
import Cookies from 'universal-cookie';
import Idioma from 'sections/idiomas/contents.json'
import $ from 'jquery';
import AnimatedHeading from 'components/animated-heading'
import { Row, Col, Container } from 'react-bootstrap'
import tw from 'sections/images/gw-06.png'
import fb from 'sections/images/gw-05.png'
import dsc from 'sections/images/gw-07.png'
import AnimationContainer from 'components/animation-container'
import gif from 'sections/team/teamgif.gif'
import RevealContent from 'components/reveal-content'
import imagen3 from 'sections/images/z3.png'
import Mt from 'sections/metadata/metadata_cryptoversox.json'
const url = gif;
class Video_list2 extends React.Component {

    constructor(props) {
        const cookies = new Cookies();
        super(props)
        let title=""
        let a=cookies.get('idioma')
        this.state={
             name:"",
             image:"",
             head:"",
             eyes:"",
             background:"",
            ears:"",
            mount:"",
            category:"",
            inteligencia :"",
            velocidad:"",
            fuerza:"",
            conciencia :"",
        }
    }
    updateDimensions = () => {
        if (this.state.height !== window.innerHeight) {
            this.setState({height: window.innerHeight})
        }
        if (this.state.width !== window.innerWidth) {
            this.setState({width: window.innerWidth})
        }
    }

    
    componentDidMount() {
        var string = document.location + '';
        let arr = string.split('/dos/');
        arr=arr[1];
       
        /*
        00 head
        1 eyes
        2background
        4 ears
        5 mount
        6 category
        7 inteligencia 
        8 velocidad
        9 ffuerza
        10 conciencia 
        */
        this.setState({
            name:Mt[arr].name,
            image:Mt[arr].image,
            head:Mt[arr].attributes[0].value,
            eyes:Mt[arr].attributes[1].value,
            background:Mt[arr].attributes[2].value,
           ears:Mt[arr].attributes[4].value,
           mount:Mt[arr].attributes[5].value,
           category:Mt[arr].attributes[6].value,
           inteligencia :Mt[arr].attributes[7].value,
           velocidad:Mt[arr].attributes[8].value,
           fuerza:Mt[arr].attributes[9].value,
           conciencia :Mt[arr].attributes[10].value,
        })
       
        this.setState({height: window.innerHeight, width: window.innerWidth})
        window.addEventListener('resize', this.updateDimensions)
        document.body.addEventListener('mousemove', (e) => {
            var amountMovedX = (e.clientX * -.1 / 8);
            var amountMovedY = (e.clientY * -.1 / 8);
            var x = document.getElementsByClassName("parallax-hero-item");
            var i;
            for (i = 0; i < x.length; i++) {
              x[i].style.transform='translate(' + amountMovedX + 'px,' + amountMovedY + 'px)'
            }
        });
    }
    
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions)
    }

    render() {

        const Section = styled.section`
            position: relative;
            .particles {
                position: absolute;
                width: 100%;
                height: 100%;
            }

            background-size: cover;
            background-repeat: no-repeat;
        `

        const VideoContainer = styled.div`
            width: 100%;
           
            display: flex;
            align-items: center;
            overflow: hidden;
            position: relative;
            video {
                position: absolute;
                
            }
            @media (max-width: 1023px) {
                width: 100%;
                
            }
        `
        const LeftCol = styled(Col)`
        display: flex;
        align-items: baseline;
        justify-content: center;
        @media (max-width: 500px) {
            margin-bottom: 50px;
        }
    `
    const AboutContainer = styled(Container)`
            padding: 40px 0;
            @media (max-width: 500px) {
                padding: 20px 0;
                
            }
        `
        const ImageContainer = styled.div`
        border-radius: 20px;
        overflow: hidden;
       
        @media (max-width: 767px) {
            margin-bottom: 50px;
        }
    `
   
    
    
    
    

    const AnimatedShadow = keyframes`
            0%   {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            10%  {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
            20%  {box-shadow: 0 28px 60px rgba(239, 78, 123, .5);}
            30%  {box-shadow: 0 28px 60px rgba(161, 102, 171, .5);}
            40% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            50% {box-shadow: 0 28px 60px rgba(4, 229, 229, .5);}
            60% {box-shadow: 0 28px 60px rgba(7, 179, 155, .5);}
            70% {box-shadow: 0 28px 60px rgba(111, 186, 130, .5);}
            80% {box-shadow: 0 28px 60px rgba(80, 115, 184, .5);}
            90% {box-shadow: 0 28px 60px rgba(16, 152, 173, .5);}
            100% {box-shadow: 0 28px 60px rgba(243, 112, 85, .5);}
        `
    const Separator = styled.div`
            height: 5px;
            width: 50px;
            background-color: #04e5e5;
            margin-bottom: 20px;
        `
        const Heading = styled.div`
            .glitch {
                font-size: 110px;
                line-height: 140px;
                font-weight: 600;
                color: #fff;
                @media (max-width: 767px) {
                    font-size: 40px;
                    line-height: 50px;
                }
            }
        `

        const SubHeading = styled.h2`
            font-size: 18px;
            font-weight: 300;
            color: #ccc;
            text-transform: uppercase;
            letter-spacing: 4px;
        `
        const Type = styled.div`
            font-size: 50px;
            line-height: 50px;
            color: #fff;
            text-transform: uppercase;
            margin-left: 6px;
            @media (min-width:768px) and (max-width:1500px) {
                font-size: 23px;
                line-height: 20px;
            }
            @media (max-width:767px) {
                font-size: 20px;
                line-height: 20px;
            }
            span {
                font-family: Teko;
            }
        `
        const gradientAnimation = keyframes`
            0% {
              background-position: 15% 0%;
            }
            50% {
              background-position: 85% 100%;
            }
            100% {
              background-position: 15% 0%;
            }
          `
          

        const HeadingBox = styled.div`
            height: 500px;
            width: 900px;
            margin: auto;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border-radius: 5px;
                background: linear-gradient(120deg, #04e5e5, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
                background-size: 300% 300%;
                clip-path: polygon(0% 100%, 10px 100%, 10px 10px, calc(100% - 10px) 10px, calc(100% - 10px) calc(100% - 10px), 10px calc(100% - 10px), 10px 100%, 100% 100%, 100% 0%, 0% 0%);
            }
            &.animate:after {
                animation: ${gradientAnimation} 2s ease-in-out infinite;
            }
            @media (max-width: 767px) {
                height: 350px;
            }
        `

        const Resume = styled.a`
            color: #fff;
            text-decoration: none;
            border-bottom: 2px solid;
            font-weight: 300;
            transition: .5s;
            margin-top: 40px;
            &:hover {
                color: #fff;
                text-decoration: none;
                border-color: #04e5e5;
            }
        `
        const ImageBox = styled.div`
        
      
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        overflow: hidden;
        @media (max-width:767px) {
            min-height: 400px;
            padding: 0;
        }
    `

    const example = keyframes`
    0% { 
        width:600px;
    }
    
    50% {
        width:590px;
    }
    
    100% {
        width:600px;
    }
        `
        const example1 = keyframes`
        0% { 
            width:300px;
        }
        
        50% {
            width:290px;
        }
        
        100% {
            width:300px;
        }
            `

    const Image = styled.img`
   
        width:600px;
      
        @media (min-width: 767px) { 
            animation-name:  ${example};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
        @media (max-width: 767px) {
            width: 300px;
            example1
            animation-name:  ${example1};
            animation-duration: 2s;
            animation-iteration-count: infinite;
        }
       
    `

    const Image1 = styled.img`
   
        width:300px;
      
       
        @media (max-width: 767px) {
            width: 150px;
        }
       
    `
    

        function aparecer(id){
            desaparecer()
                var ids="#"+id;
                $(ids).show()
              
            }
    
        function desaparecer(){
            for (let index = 1; index <= 6; index++) {
                var ids="#p"+index
                $(ids).hide()
            }
        }
      

        return (
            <Section id="home">
                <VideoContainer style={{height: `${this.state.width > 500 ? this.state.height : 1580}px`}}>
                    <video autoPlay="autoplay" loop="loop" muted style={{height: `${this.state.width >= 1023? "110%": "80%"}`}}>
                        <source src={LoopVideo} type="video/mp4" />
                    </video>
                   
                        
                       
                    <AboutContainer>


     <div class="container  pt-5">   

        <div class="row justify-content-center ">


            <div class="col-md-4 align-items-center justify-content-center  ">
                
                           <div class="row  ml-4 "> <p class="h4 text-pink text_medium">EXPLORE MORE</p></div>
                           <div class="row justify-content-center"><img src={this.state.image}  class="  tamano_imagen" alt=""></img></div> <br></br>
                           <div class="row justify-content-center">
                               <div class="col-4   fondo_verde text-dark mr-2 p-4"></div>
                               <div class="col-4   fondo_azul   p-4"></div>
                            </div>

            </div>
                           

                        <div class="col-md-6">
                           <br></br>
                        
                        
                        
                                        <h2 class=' text-verde_crypto' >{this.state.name}</h2>
                                            
                                        

                            <div  class="container color_fondo_unico">



                                        <br></br>
                                <h3 class="text-cyan font-weight-normal">TYPE</h3>
                                        <br></br>
                                        

                                <div class="row ">

                                        
                                    <div class="col-md-5 col-5 ml-3 fondo_azul text-dark  p-2 my-2 text-center">
                                    Intelligence
                                    <br></br>
                                               <h6 class="text-pink borde-negro text-center">  {this.state.inteligencia} / 5</h6>
                                    </div>
                                    <div class="col-md-5 col-5 ml-3 fondo_azul text-dark  p-2 my-2 text-center">
                                    Speed
                                    <br></br>
                                               <h6 class="text-pink borde-negro text-center">  {this.state.velocidad} / 5</h6>
                                    </div>
                                    <div class="col-md-5 col-5 ml-3 fondo_azul text-dark  p-2 my-2 text-center">
                                    Strength
                                    <br></br>
                                               <h6 class="text-pink borde-negro text-center">  {this.state.fuerza} / 5</h6>
                                    </div>
                                    <div class="col-md-5 col-5 ml-3 fondo_azul text-dark  p-2 my-2 text-center">
                                    Awareness
                                    <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.conciencia} / 5</h6>
                                    </div>
                                                                
                                </div>

                                     <br></br>
                                     <br></br>
                                    <h3 class="text-cyan">TRAITS</h3>
                                     <br></br>                 
                                        <div class="row justify-content-center ">

                                                                            
                                             <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                BACKGROUND
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.background}</h6>
                                            </div>   
                                                            
                                            <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                EARS
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.ears}</h6>
                                            </div> 
                                                           
                                            <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                EYES
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.eyes}</h6>
                                            </div> 
                                            <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                HEAD
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.head}</h6>
                                            </div> 
                                            <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                MOUTH
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.mount}</h6>
                                            </div> 
                                            <div class="col-md-3 col-5  fondo_azul text-dark mr-3 text-center my-2">
                                                CATEGORY
                                                <br></br>
                                               <h6 class="text-pink borde-negro">  {this.state.category}</h6>
                                            </div> 
                                        </div>
                        
                            </div>

                        </div>

                                                <div class="col-2"></div>
        </div>


    </div> 


                </AboutContainer>
                                
                    
                    
                </VideoContainer>
            </Section>
        )
    }

    shapes() {

        const MoveUp = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(6px);
            }
        `

        const MoveDown = keyframes`
            0% { 
                transform: translateY(0);
            }
            100% {
                transform: translateY(40px);
            }
        `
        const Shape = styled.img`
            position: absolute;
            height: 50px;
            &.move-up {
                animation: ${MoveUp} 5s infinite  alternate;
            }
            &.move-down {
                animation: ${MoveDown} 5s infinite  alternate;
            }
            @media (max-width: 767px) {
                height: 20px;
            }
        `
        


        return this.props.shapes.map((value, index) => {
            return (
                <Shape
                    style={{
                        left: `${(index+1) * 10}%`,
                        bottom: `${Math.random() *
                        (+((index+1) % 2 === 0 ? 10 : 90) - +((index+1) % 2 === 0 ? 5 : 80)) +
                        +((index+1) % 2 === 0 ? 5 : 80)}%`,
                    }}
                    key={index}
                    src={value.node.childImageSharp.fluid.src}
                    alt="shape"
                    className={Math.floor(Math.random() * 10) % 2 === 0 ? "move-up" : "move-down"}
                />

                
            )


            
        })


    }
}

export default props => (
    <StaticQuery
      query={graphql`
      query {
        shapes: allFile(filter: {extension: {regex: "/(png)/"}, relativeDirectory: {eq: "shapes"}}) {
          edges {
            node {
              childImageSharp {
                fluid(maxWidth: 100) {
                  src
                }
              }
            }
          }
        }
      }    
      `}
      render={({ shapes }) => <Video_list2 shapes={shapes.edges} {...props} />}
    />
  )