import React from 'react'
import Layout from 'components/layout'
import { graphql,Link } from 'gatsby'
import Helmet from 'react-helmet'
import TeamCrypto from 'sections/team/TeamCrypto.js'
import Partners from 'sections/clients/Partners.js'
import Lista_blancas from 'sections/services/Lista_blancas.js'
import Iniciovideo from 'sections/hero/Video_list2.js'
import Inicioabout2 from 'sections/about/Inicioabout2.js'
import {Router} from "@reach/router"
class Index extends React.Component {

  render() {

    const { site } = this.props.data
    
    return (
      <div>
        <Helmet>
          <title>{site.meta.title}</title>
          <meta name="description" content={site.meta.description} />
        </Helmet>
        <Layout
          isHome={true}
          sections={['Home', 'Road Map', 'Life Paper', 'About Us']}
        >
         

      
               <Iniciovideo path="/dos/:id" />    
        
       
        
        </Layout>
      </div>
    )
  }
}

export default Index

export const creativeOneQuery = graphql`
  query {
    site {
      meta: siteMetadata {
        title
        description
      }
    }
  }
`